<template>
    <PathDetail :idPath="id_argument" :key="id_argument" v-if="id_argument !== undefined" v-on:is-components-ready="isComponentsReady"></PathDetail>
</template>

<script>
    import PathDetail from "@/views/PathDetail";
    import {mapGetters} from 'vuex'
    import {MODULES} from "@/store/modules-enum";
    import {PATH_GETTERS} from "@/store/path/getters-enum";
    import {routesEnums} from "@/helpers/enums";

    export default {
        name: "PathDetailWrapper",
        components: {
            PathDetail
        },
        data () {
            return {
                routesNames: routesEnums,
                id_argument: undefined
            }
        },
        computed: {
            ...mapGetters(MODULES.PATH, [PATH_GETTERS.SELECTED_PATHS, PATH_GETTERS.GET_SELECTED_PATHS])
        },
        watch: {
            getSelectedPath: function (newValue) {
                this.verifyPath()
            }
        },
        mounted() {
            this.verifyPath()
        },
        methods: {
            verifyPath () {
                if (this.getSelectedPath !== undefined) {
                    if (this.getSelectedPath.id_argomento !== undefined) {
                        this.id_argument = this.getSelectedPath.id_argomento
                    } else {
                        this.$router.push({ name: this.routesNames.HOME })
                    }
                } else {
                    this.$router.push({ name: this.routesNames.HOME })
                }
            },
            isComponentsReady (label, state) {
                this.$emit('is-components-ready', label, state)
            }
        }
    }
</script>

<style scoped>

</style>