import EsercitazioneManager from "@/lib/ConcorsandoWasm/EsercitazioneManager";
import PercorsiStatsManager from "@/lib/ConcorsandoWasm/PercorsiStatsManager";
import PercorsiWrappedWorker from "@/lib/ConcorsandoWasm/PercorsiWrappedWorker";

export default class PercorsiEsercitazioneManager extends EsercitazioneManager {
  static get instance() {
    if (!this.percorsiEsercitazioneManagerInstance) {
      this.percorsiEsercitazioneManagerInstance = new PercorsiEsercitazioneManager(PercorsiStatsManager.instance, PercorsiWrappedWorker.instance);
    }
    return this.percorsiEsercitazioneManagerInstance;
  }
}
