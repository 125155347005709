<template>
    <div id="pathDetail">

        <div class="card p-3 bg-warning" v-if="selectedPath !== undefined && selectedPath !== null && selectedPath.stato === 2 && isUpdate === false">
            <span class="font-weight-bolder">{{$t('pathDetail.modale.loadingDb.sottotitolo')}}</span>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body" v-if="selectedPath !== null">
                        <h3 class="card-title font-size-15 mt-0">{{selectedPath.argomento}}</h3>
                        <!-- <p class="card-text">
                            {{selectedPath.concorso}}<br>
                            {{selectedPath.anno}}
                        </p> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-content">
            <!-- ### TAB Esercitazione Percorsi -->
            <div class="tab-pane active" id="percorsi" role="tabpanel">
                <div class="card">
                    <div class="card-body">
                        <h5 class="header-title mb-4 text-uppercase">
                            <i class="mdi mdi-format-list-bulleted-square font-size-24 mr-1 align-middle"></i> {{$t('pathDetail.elencoArgomentiDisponibili')}}
                        </h5>
                        <p>{{$t('pathDetail.selezionaUnoPiùArgomentiPerEsercitarti')}}</p>
                        <div class="table-responsive font-size-14">

                            <table class="table table-centered table-hover mb-0">
                                <thead class="thead-light">
                                    <tr class="font-weight-bold">
                                        <th scope="col" class="font-weight-bold" width="3%"></th>
                                        <th scope="col">{{$t('pathDetail.argomento')}}</th>
                                        <th scope="col"></th>
                                        <th scope="col" class="text-center w-25">{{$t('pathDetail.totDomande')}}</th>
                                    </tr>
                                </thead>
                            </table>

                            <div class="container-fluid">
                                <div class="row border-center-content">
                                    <div class="col-md-1 padding-element custom-max-width">
                                        <div class="custom-control custom-radio">
                                            <input type="radio" id="tutte" class="custom-radio-size custom-control-input" value="all" v-model="configuration.pathSelected">
                                            <label class="custom-control-label cursorPointer" for="tutte"></label>
                                        </div>
                                    </div>
                                    <div class="col-md-7 padding-element" style="padding-left: 0!important;">
                                        <span class="custom-font">{{$t('pathDetail.tutte')}}</span>
                                    </div>
                                    <div class="col-md-1 padding-element">
                                    </div>
                                    <div class="col-md-3 text-center ptbr-element pl-custom">
                                        <span class="font-size-20" style="color: #3051d3">{{totDomande}}</span><br>
                                        <span class="badge badge-success font-size-11" v-if="stateOfCompletion.tot !== undefined">{{parsePct(stateOfCompletion.tot)}}%</span>
                                    </div>
                                </div>

                                <template v-for="(subject, index) in subjects">
                                    <div class="row border-center-content">
                                        <div class="col-md-1 padding-element custom-max-width">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" class="custom-radio-size custom-control-input" :id="'radio-' + subject.id + '-' + index" :value="subject.id" v-model="configuration.pathSelected">
                                                <label class="custom-control-label cursorPointer" :for="'radio-' + subject.id + '-' + index" :value="subject.id"></label>
                                            </div>
                                        </div>
                                        <div class="col-md-7 padding-element" style="padding-left: 0!important;">
                                            <span class="custom-font">{{subject.name}}</span>
                                            <div class="font-size-11" v-if="subject.sotto_percorsi.length > 0">{{$t('pathDetail.numeroSottoargomenti')}}: {{subject.sotto_percorsi.length}}</div>
                                        </div>
                                        <div class="col-md-1 text-center padding-element">
                                            <a class="font-size-20" data-toggle="collapse" v-if="subject.sotto_percorsi.length > 0" @click.prevent="changeIconAccordion('#icon-group-' + subject.id + '-' + index)" :href="'#accordion-' + subject.id + '-' + index" aria-expanded="true" :aria-controls="'accordion-' + subject.id + '-' + index">
                                                <i v-if="!isOpen(subject.open)" class="apertura mdi mdi-file-document-box-plus-outline align-middle" :id="'icon-group-' + subject.id + '-' + index"></i>
                                                <i v-else class="apertura mdi align-middle mdi-file-document-box-minus-outline" :id="'icon-group-' + subject.id + '-' + index"></i>
                                            </a>
                                        </div>
                                        <div class="col-md-3 text-center ptbr-element pl-custom">
                                            <span class="font-size-20" style="color: #3051d3">{{getQuestionsForPath(subject.id)}}</span><br>
                                            <span class="badge badge-success font-size-11" v-if="stateOfCompletion.by_materia !== undefined">{{getCompletitionByMateria(subject.id)}}%</span>
                                        </div>

                                        <!-- FIRST GROUP LEVEL -->
                                        <div :class="[(isOpen(subject.open) ? 'show' : ''), 'container-fluid collapse sottogruppo']" :id="'accordion-' + subject.id + '-' + index">
                                            <template v-for="(subPathOne, indexOne) in subject.sotto_percorsi">
                                                <div class="row align-items-center bg-subpath">
                                                    <div class="col-md-1 padding-element custom-max-width">
                                                        <div class="custom-control custom-radio">
                                                            <input type="radio" class="custom-radio-size custom-control-input" :id="'radioOne-' + index + indexOne + subPathOne.indentazione" :value="subPathOne.id" v-model="configuration.pathSelected">
                                                            <label class="custom-control-label cursorPointer" :for="'radioOne-' + index + indexOne + subPathOne.indentazione" :value="subPathOne.id"></label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-7 padding-element" style="padding-left: 25px!important;">
                                                        <span class="custom-font">{{subPathOne.name}}</span><br>
                                                        <div class="font-size-11" v-if="subPathOne.sotto_percorsi.length > 0">{{$t('pathDetail.numeroSottoargomenti')}}: {{subPathOne.sotto_percorsi.length}}</div>
                                                    </div>
                                                    <div class="col-md-1 text-center padding-element">
                                                        <a class="font-size-20" v-if="subPathOne.sotto_percorsi.length > 0" @click.prevent="changeIconAccordion('#icon-first-group-' + index + '-' + indexOne)" data-toggle="collapse" :href="'#accordionOne-' + index + indexOne + subPathOne.indentazione" aria-expanded="true" :aria-controls="'accordionOne-' + indexOne + subPathOne.indentazione">
                                                            <i v-if="!isOpen(subPathOne.open)" class="apertura mdi mdi-file-document-box-plus-outline align-middle" :id="'icon-first-group-' + index + '-' + indexOne"></i>
                                                            <i v-else class="apertura mdi align-middle mdi-file-document-box-minus-outline" :id="'icon-first-group-' + index + '-' + indexOne"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-md-3 text-center ptbr-element pl-custom">
                                                        <span class="font-size-20" style="color: #3051d3">{{getQuestionsForPath(subPathOne.id)}}</span><br>
                                                        <span class="badge badge-success font-size-11" v-if="stateOfCompletion.by_materia !== undefined">{{getCompletitionByMateria(subPathOne.id)}}%</span>
                                                    </div>

                                                    <!-- SECOND GROUP LEVEL -->
                                                    <div :class="[(isOpen(subPathOne.open) ? 'show' : ''), 'container-fluid collapse sottogruppo']" :id="'accordionOne-' + index + indexOne + subPathOne.indentazione">
                                                        <template v-for="(subPathTwo, indexTwo) in subPathOne.sotto_percorsi">
                                                            <div class="row align-items-center bg-subpath">
                                                                <div class="col-md-1 padding-element custom-max-width">
                                                                    <div class="custom-control custom-radio">
                                                                        <input type="radio" class="custom-radio-size custom-control-input" :id="'radioTwo-' + index + indexOne + indexTwo + subPathOne.indentazione" :value="subPathTwo.id" v-model="configuration.pathSelected">
                                                                        <label class="custom-control-label cursorPointer" :for="'radioTwo-' + index + indexOne + indexTwo + subPathOne.indentazione" :value="subPathTwo.id"></label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-7 padding-element" style="padding-left: 50px!important;">
                                                                    <span class="custom-font">{{subPathTwo.name}}</span><br>
                                                                    <div class="font-size-11" v-if="subPathTwo.sotto_percorsi.length > 0">{{$t('pathDetail.numeroSottoargomenti')}}: {{subPathTwo.sotto_percorsi.length}}</div>
                                                                </div>
                                                                <div class="col-md-1 text-center padding-element">
                                                                    <a class="font-size-20" v-if="subPathTwo.sotto_percorsi.length > 0" @click.prevent="changeIconAccordion('#icon-second-group-' + index + indexOne + '-' + indexTwo)" data-toggle="collapse" :href="'#accordionTwo-' + index + indexOne + indexTwo + subPathTwo.indentazione" aria-expanded="true" :aria-controls="'accordionTwo-' + index + indexOne + indexTwo + subPathTwo.indentazione">
                                                                        <i v-if="!isOpen(subPathTwo.open)" class="apertura mdi mdi-file-document-box-plus-outline align-middle" :id="'icon-second-group-' + index + indexOne + '-' + indexTwo"></i>
                                                                        <i v-else class="apertura mdi align-middle mdi-file-document-box-minus-outline" :id="'icon-second-group-' + index + indexOne + '-' + indexTwo"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="col-md-3 text-center ptbr-element pl-custom">
                                                                    <span class="font-size-20" style="color: #3051d3">{{getQuestionsForPath(subPathTwo.id)}}</span><br>
                                                                    <span class="badge badge-success font-size-11" v-if="stateOfCompletion.by_materia !== undefined">{{getCompletitionByMateria(subPathTwo.id)}}%</span>
                                                                </div>

                                                                <!-- THIRD GROUP LEVEL -->
                                                                <div :class="[(isOpen(subPathTwo.open) ? 'show' : ''), 'container-fluid collapse sottogruppo']" :id="'accordionTwo-' + index + indexOne + indexTwo + subPathTwo.indentazione">
                                                                    <template v-for="(subPathThree, indexThree) in subPathTwo.sotto_percorsi">
                                                                        <div class="row align-items-center bg-subpath">
                                                                            <div class="col-md-1 padding-element custom-max-width">
                                                                                <div class="custom-control custom-radio">
                                                                                    <input type="radio" class="custom-radio-size custom-control-input" :id="'radioThree-' + index + indexOne + indexTwo + indexThree + subPathThree.indentazione" :value="subPathThree.id" v-model="configuration.pathSelected">
                                                                                    <label class="custom-control-label cursorPointer" :for="'radioThree-' + index + indexOne + indexTwo + indexThree + subPathThree.indentazione" :value="subPathThree.id"></label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-7 padding-element" style="padding-left: 75px!important;">
                                                                                <span class="custom-font">{{subPathThree.name}}</span><br>
                                                                                <div class="font-size-11" v-if="subPathThree.sotto_percorsi.length > 0">{{$t('pathDetail.numeroSottoargomenti')}}: {{subPathThree.sotto_percorsi.length}}</div>
                                                                            </div>
                                                                            <div class="col-md-1 text-center padding-element">
                                                                                <a class="font-size-20" v-if="subPathThree.sotto_percorsi.length > 0" @click.prevent="changeIconAccordion('#icon-third-group-' + index + indexOne + indexTwo + '-' + indexThree)" data-toggle="collapse" :href="'#accordionThree-' + index + indexOne + indexTwo + indexThree + subPathThree.indentazione" aria-expanded="true" :aria-controls="'accordionThree-' + index + indexOne + indexTwo + indexThree + subPathThree.indentazione">
                                                                                    <i v-if="!isOpen(subPathThree.open)" class="apertura mdi mdi-file-document-box-plus-outline align-middle" :id="'icon-third-group-' + index + indexOne + indexTwo + '-' + indexThree"></i>
                                                                                    <i v-else class="apertura mdi align-middle mdi-file-document-box-minus-outline" :id="'icon-third-group-' + index + indexOne + indexTwo + '-' + indexThree"></i>
                                                                                </a>
                                                                            </div>
                                                                            <div class="col-md-3 text-center ptbr-element pl-custom">
                                                                                <span class="font-size-20" style="color: #3051d3">{{getQuestionsForPath(subPathThree.id)}}</span><br>
                                                                                <span class="badge badge-success font-size-11" v-if="stateOfCompletion.by_materia !== undefined">{{getCompletitionByMateria(subPathThree.id)}}%</span>
                                                                            </div>

                                                                            <!-- FOURTH GROUP LEVEL -->
                                                                            <div :class="[(isOpen(subPathThree.open) ? 'show' : ''), 'container-fluid collapse sottogruppo']" :id="'accordionThree-' + index + indexOne + indexTwo + indexThree + subPathThree.indentazione">
                                                                                <template v-for="(subPathFourth, indexFourth) in subPathThree.sotto_percorsi">
                                                                                    <div class="row align-items-center bg-subpath">
                                                                                        <div class="col-md-1 padding-element custom-max-width">
                                                                                            <div class="custom-control custom-radio">
                                                                                                <input type="radio" class="custom-radio-size custom-control-input" :id="'radioFourth-' + index + indexOne + indexTwo + indexThree + indexFourth + subPathFourth.indentazione" :value="subPathFourth.id" v-model="configuration.pathSelected">
                                                                                                <label class="custom-control-label cursorPointer" :for="'radioFourth-' + index + indexOne + indexTwo + indexThree + indexFourth + subPathFourth.indentazione" :value="subPathFourth.id"></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-md-7 padding-element" style="padding-left: 100px!important;">
                                                                                            <span class="custom-font">{{subPathFourth.name}}</span><br>
                                                                                            <div class="font-size-11" v-if="subPathFourth.sotto_percorsi.length > 0">{{$t('pathDetail.numeroSottoargomenti')}}: {{subPathFourth.sotto_percorsi.length}}</div>
                                                                                        </div>
                                                                                        <div class="col-md-1 text-center padding-element">
                                                                                            <a class="font-size-20" v-if="subPathFourth.sotto_percorsi.length > 0" @click.prevent="changeIconAccordion('#icon-fourth-group-' + index + indexOne + indexTwo + indexThree + '-' + indexFourth)" data-toggle="collapse" :href="'#accordionFourth-' + index + indexOne + indexTwo + indexThree + indexFourth + subPathFourth.indentazione" aria-expanded="true" :aria-controls="'accordionFourth-' + index + indexOne + indexTwo + indexThree + indexFourth + subPathFourth.indentazione">
                                                                                                <i v-if="!isOpen(subPathFourth.open)" class="apertura mdi mdi-file-document-box-plus-outline align-middle" :id="'icon-fourth-group-' + index + indexOne + indexTwo + indexThree + '-' + indexFourth"></i>
                                                                                                <i v-else class="apertura mdi align-middle mdi-file-document-box-minus-outline" :id="'icon-fourth-group-' + index + indexOne + indexTwo + indexThree + '-' + indexFourth"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                        <div class="col-md-3 text-center ptbr-element pl-custom">
                                                                                            <span class="font-size-20" style="color: #3051d3">{{getQuestionsForPath(subPathFourth.id)}}</span><br>
                                                                                            <span class="badge badge-success font-size-11" v-if="stateOfCompletion.by_materia !== undefined">{{getCompletitionByMateria(subPathFourth.id)}}%</span>
                                                                                        </div>

                                                                                        <!-- FIFTH GROUP LEVEL -->
                                                                                        <div :class="[(isOpen(subPathFourth.open) ? 'show' : ''), 'container-fluid collapse sottogruppo']" :id="'accordionFourth-' + index + indexOne + indexTwo + indexThree + indexFourth + subPathFourth.indentazione">
                                                                                            <template v-for="(subPathFifth, indexFifth) in subPathFourth.sotto_percorsi">
                                                                                                <div class="row align-items-center bg-subpath">
                                                                                                    <div class="col-md-1 padding-element custom-max-width">
                                                                                                        <div class="custom-control custom-radio">
                                                                                                            <input type="radio" class="custom-radio-size custom-control-input" :id="'radioFifth-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + subPathFifth.indentazione" :value="subPathFifth.id" v-model="configuration.pathSelected">
                                                                                                            <label class="custom-control-label cursorPointer" :for="'radioFifth-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + subPathFifth.indentazione" :value="subPathFifth.id"></label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-md-7 padding-element" style="padding-left: 125px!important;">
                                                                                                        <span class="custom-font">{{subPathFifth.name}}</span><br>
                                                                                                        <div class="font-size-11" v-if="subPathFifth.sotto_percorsi.length > 0">{{$t('pathDetail.numeroSottoargomenti')}}: {{subPathFifth.sotto_percorsi.length}}</div>
                                                                                                    </div>
                                                                                                    <div class="col-md-1 text-center padding-element">
                                                                                                        <a class="font-size-20" v-if="subPathFifth.sotto_percorsi.length > 0" @click.prevent="changeIconAccordion('#icon-fifth-group-' + index + indexOne + indexTwo + indexThree + indexFourth + '-' + indexFifth)" data-toggle="collapse" :href="'#accordionFifth-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + subPathFifth.indentazione" aria-expanded="true" :aria-controls="'accordionFifth-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + subPathFifth.indentazione">
                                                                                                            <i v-if="!isOpen(subPathFifth.open)" class="apertura mdi mdi-file-document-box-plus-outline align-middle" :id="'icon-fifth-group-' + index + indexOne + indexTwo + indexThree + indexFourth + '-' + indexFifth"></i>
                                                                                                            <i v-else class="apertura mdi align-middle mdi-file-document-box-minus-outline" :id="'icon-fifth-group-' + index + indexOne + indexTwo + indexThree + indexFourth + '-' + indexFifth"></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div class="col-md-3 text-center ptbr-element pl-custom">
                                                                                                        <span class="font-size-20" style="color: #3051d3">{{getQuestionsForPath(subPathFifth.id)}}</span><br>
                                                                                                        <span class="badge badge-success font-size-11" v-if="stateOfCompletion.by_materia !== undefined">{{getCompletitionByMateria(subPathFifth.id)}}%</span>
                                                                                                    </div>

                                                                                                    <!-- SIXTH GROUP LEVEL -->
                                                                                                    <div :class="[(isOpen(subPathFifth.open) ? 'show' : ''), 'container-fluid collapse sottogruppo']" :id="'accordionFifth-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + subPathFifth.indentazione">
                                                                                                        <template v-for="(subPathSixth, indexSixth) in subPathFifth.sotto_percorsi">
                                                                                                            <div class="row align-items-center bg-subpath">
                                                                                                                <div class="col-md-1 padding-element custom-max-width">
                                                                                                                    <div class="custom-control custom-radio">
                                                                                                                        <input type="radio" class="custom-radio-size custom-control-input" :id="'radioSixth-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + indexSixth + subPathSixth.indentazione" :value="subPathSixth.id" v-model="configuration.pathSelected">
                                                                                                                        <label class="custom-control-label cursorPointer" :for="'radioSixth-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + indexSixth + subPathSixth.indentazione" :value="subPathSixth.id"></label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-md-7 padding-element" style="padding-left: 150px!important;">
                                                                                                                    <span class="custom-font">{{subPathSixth.name}}</span><br>
                                                                                                                    <div class="font-size-11" v-if="subPathSixth.sotto_percorsi.length > 0">{{$t('pathDetail.numeroSottoargomenti')}}: {{subPathSixth.sotto_percorsi.length}}</div>
                                                                                                                </div>
                                                                                                                <div class="col-md-1 text-center padding-element">
                                                                                                                    <a class="font-size-20" v-if="subPathSixth.sotto_percorsi.length > 0" @click.prevent="changeIconAccordion('#icon-sixth-group-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + '-' + indexSixth)" data-toggle="collapse" :href="'#accordionSixth-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + indexSixth + subPathSixth.indentazione" aria-expanded="true" :aria-controls="'accordionSixth-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + indexSixth + subPathSixth.indentazione">
                                                                                                                        <i v-if="!isOpen(subPathSixth.open)" class="apertura mdi mdi-file-document-box-plus-outline align-middle" :id="'icon-sixth-group-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + '-' + indexSixth"></i>
                                                                                                                        <i v-else class="apertura mdi align-middle mdi-file-document-box-minus-outline" :id="'icon-sixth-group-' + index + indexOne + indexTwo + indexThree + indexFourth + indexFifth + '-' + indexSixth"></i>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                                <div class="col-md-3 text-center ptbr-element pl-custom">
                                                                                                                    <span class="font-size-20" style="color: #3051d3">{{getQuestionsForPath(subPathSixth.id)}}</span><br>
                                                                                                                    <span class="badge badge-success font-size-11" v-if="stateOfCompletion.by_materia !== undefined">{{getCompletitionByMateria(subPathSixth.id)}}%</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </template>
                                                                                                    </div>
                                                                                                    <!-- /SIXTH GROUP LEVEL -->
                                                                                                </div>
                                                                                            </template>
                                                                                        </div>
                                                                                        <!-- /FIFTH GROUP LEVEL -->
                                                                                    </div>
                                                                                </template>
                                                                            </div>
                                                                            <!-- /FOURTH GROUP LEVEL -->
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                                <!-- /THIRD GROUP LEVEL -->
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <!-- /SECOND GROUP LEVEL -->
                                                </div>
                                            </template>
                                        </div>
                                        <!-- /FIRST GROUP LEVEL -->

                                    </div>
                                </template>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="card" >
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-8"><h5 class="header-title mt-2">{{$t('pathDetail.filtri')}}</h5></div>
                            <div class="col-sm-12 col-md-4 text-right">
                                <button class="btn btn-light btn-rounded dropdown-toggle btn-sm text-right" type="button" @click="computeCollapseFilterMessage()" data-toggle="collapse" data-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample">
                                    <i class="mdi mdi-settings-outline mr-1"></i> {{messageFilter}}
                                </button>
                            </div>
                        </div>
                        <div id="collapseExample" :class="['row mt-3 collapse', isInitialFilterDefault ? '' : 'show']">
                            <div class="col-md-6 col-sm-6 custom-pt-sm-3">
                                <h5 class="header-title">{{$t('pathDetail.esercitatiParoleChiavi')}}</h5>
                                <form>
                                    <div class="form-group mb-2">
                                        <label for="selectize-tags">{{$t('pathDetail.scriviLaParolaDaSelezionare')}}</label>
                                        <input type="text" id="selectize-tags" :value="keyWordsComputed">
                                    </div>
                                    <div class="custom-control custom-radio mb-2">
                                        <input type="radio" id="customRadio99" v-model="configuration.typeSearchWordKey" class="custom-control-input" value="ANY">
                                        <label class="custom-control-label cursorPointer" for="customRadio99">{{$t('pathDetail.ancheSoloPerAlcuneParole')}}</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-2">
                                        <input type="radio" id="customRadio88" v-model="configuration.typeSearchWordKey" class="custom-control-input" value="ALL">
                                        <label class="custom-control-label cursorPointer" for="customRadio88">{{$t('pathDetail.tutteLeParoleIndicate')}}</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-2 pl-0 pt-3">
                                        <label class="switch">
                                          <input type="checkbox" v-model="configuration.favorite">
                                          <span class="slider round"></span>
                                        </label>
                                        <span class="pl-2 pt-1">{{$t('pathDetail.contrassegnate')}}</span>
                                    </div>
                                    <div class="custom-control custom-radio mb-2 pl-0 pt-3">
                                        <label class="switch">
                                            <input type="checkbox" v-model="configuration.commento">
                                            <span class="slider round"></span>
                                        </label>
                                        <span class="pl-2 pt-1">{{$t('competitionDetail.conSpiegazione')}}</span>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <div class="mt-4 mt-sm-0">
                                    <h5 class="font-size-14 mb-3">{{$t('competitionDetail.ordinamentoDomande')}}</h5>
                                    <div class="custom-control custom-radio mb-2">
                                        <input type="radio" id="customRadio1" v-model="orderQuestions" class="custom-control-input" value="CASUALE">
                                        <label class="custom-control-label cursorPointer" for="customRadio1">{{$t('competitionDetail.casuale')}}</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-2">
                                        <input type="radio" id="customRadio2" v-model="orderQuestions" class="custom-control-input" value="ALFABETICO">
                                        <label class="custom-control-label cursorPointer" for="customRadio2">{{$t('competitionDetail.alfabetico')}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-8">
                                <h5 class="header-title mb-4">{{$t('pathDetail.livelloDiPreparazione')}}</h5>
                                <p>{{$t('pathDetail.esercitatiSuSingolePiùArgomenti')}}</p>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="table-responsive">
                                    <table class="table table-centered table-hover mb-0">
                                        <tbody>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-selectAllMateria" v-model="summarySelectAllMateria" @click="onSelectAllLevelsClick($event)">
                                                        <label class="custom-control-label cursorPointer" for="summary-selectAllMateria"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('pathDetail.tutte')}}
                                                </th>
                                                <td class="text-center">
                                                    <div class="field-loader mx-auto" v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.totDomande}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-leSai" v-model="summary.leSai">
                                                        <label class="custom-control-label cursorPointer" for="summary-leSai"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('pathDetail.leSai')}}
                                                </th>
                                                <td class="text-center">
                                                    <div v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.leSai}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-ancoraUnPiccoloSforzo" v-model="summary.ancoraUnPiccoloSforzo">
                                                        <label class="custom-control-label cursorPointer" for="summary-ancoraUnPiccoloSforzo"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('pathDetail.ancoraUnPiccoloSforzo')}}
                                                </th>
                                                <td class="text-center">
                                                    <div v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.ancoraUnPiccoloSforzo}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-ripetile" v-model="summary.ripetile">
                                                        <label class="custom-control-label cursorPointer" for="summary-ripetile"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('pathDetail.meglioRipetere')}}
                                                </th>
                                                <td class="text-center">
                                                    <div v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.ripetile}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-nonLeSai" v-model="summary.nonLeSai">
                                                        <label class="custom-control-label cursorPointer" for="summary-nonLeSai"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('pathDetail.nonLeSo')}}
                                                </th>
                                                <td class="text-center">
                                                    <div v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.nonLeSai}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" width="3%">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="summary-nonRisposte" v-model="summary.nonRisposte">
                                                        <label class="custom-control-label cursorPointer" for="summary-nonRisposte"></label>
                                                    </div>
                                                </th>
                                                <th scope="row">
                                                    {{$t('pathDetail.nonRisposte')}}
                                                </th>
                                                <td class="text-center">
                                                    <div v-if="fieldLoader"></div>
                                                    <strong class="text-primary" v-else>{{currentStats.nonRisposte}}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button type="button" data-toggle="modal" data-target="#delStatisticsModal" class="ml-2 mt-3 btn btn-danger btn-sm btn-rounded waves-effect waves-light">{{$t('pathDetail.azzeraStatistiche')}}</button>
                                    <button type="button" data-toggle="modal" data-target="#modalSync" class="ml-2 mt-3 btn btn-primary btn-sm btn-rounded waves-effect waves-light">{{$t('pathDetail.forzaSincronizzazione')}}</button>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="text-center">
                                    <div class="mb-2" v-if="activeFilters > 0">{{$t('pathDetail.filtri')}}: {{activeFilters}} {{$t('pathDetail.attivi')}}</div>
                                    <h2 class="mb-4">
                                        <div class="field-loader-sum mx-auto" v-if="fieldLoader"></div>
                                        <template v-else>
                                            <span class="font-weight-bold text-danger">{{summedQuestions}}</span>
                                            <br>
                                        </template>
                                        <span style="font-size:12px; font-weight:normal">{{$t('pathDetail.totaleDomandeSelezionate')}}</span>
                                    </h2>
                                    <button id="avvia-test" type="button" @click="setupExercise(exerciseMode.ESEGUITA)" :class="[((fieldLoader || summedQuestions < 1) ? 'cursorNotAllowed' : ''), 'btn btn-rounded btn-lg btn-success mt-3 mb-3']" :disabled="fieldLoader || summedQuestions < 1">
                                        <i class="mdi mdi-play-circle-outline font-size-24 align-middle"></i> {{$t('pathDetail.avvia')}}
                                    </button>
                                    <br>
                                    <button id="button-ascolto" type="button" @click="verifyIfQuizHasImage()" :class="[((fieldLoader || summedQuestions < 1) ? 'cursorNotAllowed' : ''), 'btn btn-outline btn-secondary btn-sm mt-3 mb-3']" :disabled="fieldLoader || summedQuestions < 1">
                                        <i class="mdi mdi-headphones font-size-24 align-middle"></i> <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ### Fine -->
        </div>

        <!-- Modal delete stats -->
        <div class="modal fade" id="delStatisticsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">{{$t('pathDetail.modale.tabellaArgomenti.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive font-size-14">
                            <table class="table table-centered table-hover mb-0">
                                <thead class="thead-light">
                                <tr class="font-weight-bold">
                                    <th scope="col" class="font-weight-bold" width="3%">
                                    </th>
                                    <th scope="col" class="pl-0">{{$t('pathDetail.argomento')}}</th>
                                </tr>
                                </thead>
                                <tbody class="record_table">
                                    <tr>
                                        <th scope="row" width="3%">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" id="modalSelectAllMateria" v-model="modalSelectAllMateria">
                                                <label class="custom-control-label cursorPointer" for="modalSelectAllMateria"></label>
                                            </div>
                                        </th>
                                        <th class="pl-0">
                                            {{$t('pathDetail.tutte')}}
                                        </th>
                                    </tr>
                                    <template v-for="(subject, index) in subjects">
                                        <tr>
                                            <th scope="row" width="3%">
                                                <div class="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" class="custom-control-input" :id="'modal' + subject.id" v-model="modalSubjects[subject.id]">
                                                    <label class="custom-control-label cursorPointer" :for="'modal' + subject.id"></label>
                                                </div>
                                            </th>
                                            <th class="pl-0">
                                                {{subject.name}}
                                            </th>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" :class="[(verifyIfNeedToDisableConfirmDelStatsButton() ? 'cursorNotAllowed' : ''), 'btn btn-primary']" @click="emptyStats()" :disabled="verifyIfNeedToDisableConfirmDelStatsButton()" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Modal delete stats -->

        <!-- Modal error -->
        <div id="modalErrorPathDetail" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorLabel">{{$t('pathDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-error" class="modal-body">
                        <p id="message-body-error-path-detail"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal error -->

        <!-- Modal error path -->
        <div id="modalErrorPath" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorPathLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorPathLabel">{{$t('pathDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-error-path" class="modal-body">
                        <p id="message-body-path"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="$router.push({ name: routesNames.HOME })" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal error path -->

        <!-- Modal sync -->
        <div id="modalSync" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalSyncLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalSyncLabel">{{$t('pathDetail.modale.sync.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-sync" class="modal-body">
                        <p id="message-body-sync">{{$t('pathDetail.modale.sync.sottotitolo')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" data-toggle="modal" data-target="#modalConfirmForceSyncFromWeb" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('pathDetail.modale.sync.sincronizzaDalWeb')}}</button>
                        <button type="button" data-toggle="modal" data-target="#modalConfirmForceSyncToWeb" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('pathDetail.modale.sync.sincronizzaVersoIlWeb')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal sync -->

        <!-- Modal download db -->
        <div id="modalDownloadDb" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalDownloadDbLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div id="modal-body-download" class="modal-body text-center">
                        <template v-if="downloadProgressValue <= 99">
                            <h5 class="modal-title mt-0">{{$t('pathDetail.modale.downloadDb.downloadDati')}}</h5>
                        </template>
                        <template v-else>
                            <h5 class="modal-title mt-0">{{$t('pathDetail.modale.downloadDb.salvataggioDati')}}</h5>
                        </template>
                        <div class="pt-2">
                            <div class="progress" style="height: 2rem!important;" v-if="downloadProgressValue <= 99">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="'width: ' + downloadProgressValue + '%'" :aria-valuenow="downloadProgressValue" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div v-else class="loader mx-auto"></div>
                        </div>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal download db -->

        <!-- Modal limit quiz -->
        <div id="modalLimitQuiz" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLimitQuizLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalLimitQuizLabel">{{$t('pathDetail.modale.limitQuiz.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-limit" class="modal-body">
                        <p id="message-body-limit">{{$t('pathDetail.modale.limitQuiz.sottotitolo')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.SUBSCRIPTION })">{{$t('pathDetail.modale.limitQuiz.passaPremium')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal limit quiz -->

        <!-- Modal update db -->
        <div id="modalUpdateDb" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalUpdateDbLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalmodalUpdateDbLabel">{{$t('pathDetail.modale.updateDb.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-update-db" class="modal-body">
                        <p id="message-body-update-db">{{$t('pathDetail.modale.updateDb.sottotitolo')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" id="button-update-db" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal update db -->

        <!-- Modal quiz has images -->
        <div id="modalQuizHasImages" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalQuizHasImagesLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalQuizHasImagesLabel">{{$t('pathDetail.modale.quizHasImage.titolo')}}</h5>
                    </div>
                    <div id="modal-body-quiz-has-image" class="modal-body">
                        <p id="message-body-quiz-has-image">{{$t('pathDetail.modale.quizHasImage.alcuniQuizHannoImmagine')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" @click="setupExercise(exerciseMode.ASCOLTATA)" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal quiz has images -->

        <!-- Modal force sync error -->
        <div id="modalErrorForceSync" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorForceSyncLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorForceSyncLabel">{{$t('pathDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-error-force-sync" class="modal-body">
                        <p id="message-body-error-force-sync"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal force sync error error -->

        <!-- Modal confirm sync from web -->
        <div id="modalConfirmForceSyncFromWeb" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalConfirmForceSyncFromWebLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalConfirmForceSyncFromWebLabel">{{$t('pathDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-confirm-force-sync-from" class="modal-body">
                        <p id="message-body-confirm-force-sync-from">{{$t('pathDetail.modale.sync.confermaFromWeb')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" @click="forceSync('fromWeb')" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal confirm sync from web -->

        <!-- Modal confirm sync to web -->
        <div id="modalConfirmForceSyncToWeb" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalConfirmForceSyncToWebLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalConfirmForceSyncToWebLabel">{{$t('pathDetail.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-confirm-force-sync-to" class="modal-body">
                        <p id="message-body-confirm-force-sync-to">{{$t('pathDetail.modale.sync.confermaToWeb')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" @click="forceSync('toWeb')" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal confirm sync to web -->

    </div>
</template>

<script>
    import {MODULES} from "@/store/modules-enum";
    import {PATH_GETTERS} from "@/store/path/getters-enum";
    import {mapActions, mapGetters} from 'vuex'
    import $ from 'jquery'
    import 'bootstrap-touchspin'
    import 'selectize'
    import {PATH_ACTIONS} from "@/store/path/actions-enum";
    import PercorsiStatsManager from '../lib/ConcorsandoWasm/PercorsiStatsManager'
    import {LivelliConoscenzaEnum} from "@/lib/ConcorsandoWasm/Constants";
    import PercorsiWrappedWorker from "../lib/ConcorsandoWasm/PercorsiWrappedWorker";
    import _ from 'lodash'
    import moment from 'moment';
    import {PATH_MUTATIONS} from "@/store/path/mutations-enum";
    import {AUTH_GETTERS} from "@/store/auth/getters-enum";
    import ImagesClient from "@/lib/ConcorsandoWasm/ImagesClient";
    import Swal from "sweetalert2";
    import { QuizOrderEnum, AnswerOrderEnum, SearchModeEnum } from "@/lib/ConcorsandoWasm/Constants";
    import PercorsiEsercitazioneManager from "@/lib/ConcorsandoWasm/PercorsiEsercitazioneManager";
    import {PROFILE_ACTIONS} from "@/store/profile/actions-enum";
    import {ModalitaEsercitazioneEnum} from "@/lib/ConcorsandoWasm/Constants";
    import VueSlider from 'vue-slider-component'
    import {competitionDetailTabsEnums, routesEnums} from "@/helpers/enums";
    import {COMPETITION_ACTIONS} from "@/store/competition/actions-enum";
    import {getNumberBetween, includesSottoPercorso, sanitizeSottoPercorsoId} from "@/lib/utils";
    import firebase from "firebase";

    const percorsiWorker = PercorsiWrappedWorker.instance
    const percorsiStatsManager = PercorsiStatsManager.instance
    const imagesClient = ImagesClient.instance
    const percorsiEsercitazioneManager = PercorsiEsercitazioneManager.instance

    let performSearchTimeout = null
    let lastSearchNonce = null

    const DEFAULT_ORDER_QUESTIONS = QuizOrderEnum.CASUALE;

    export default {
        name: "PathDetail",
        props: ['idPath'],
        components: {
            VueSlider
        },
        data () {
            return {
                greetingSpeech: new window.SpeechSynthesisUtterance(),
                routesNames: routesEnums,
                isLoading: true,
                exerciseMode: ModalitaEsercitazioneEnum,
                isUpdate: false,
                downloadProgressValue: 0,
                messageFilter: this.$t('pathDetail.apriImpostazioni'),
                orderQuestions: DEFAULT_ORDER_QUESTIONS,
                orderAnswers: AnswerOrderEnum.CASUALE,
                configuration: {
                    pathSelected: "",
                    keyWords: [],
                    typeSearchWordKey: SearchModeEnum.ANY,
                    favorite: false,
                    commento: false,
                },
                metaConfig: {
                    isRangeStopCustom: false,
                },
                isInitialFilterDefault: true,
                filtersReady: false,
                savedConfiguration: {},
                fieldLoader: false,
                selectedPath: null,
                modalSelectAllMateria: true,
                modalSubjects: {},
                totDomande: 0,
                questions: 0,
                summarySelectAllMateria: false,
                summary: {
                    ancoraUnPiccoloSforzo: false,
                    nonRisposte: false,
                    nonLeSai: false,
                    ripetile: false,
                    leSai: false
                },
                currentStats: {},
                metaData: [],
                subjects: [],
                countRequests: 0,
                stateOfCompletion: {
                    by_materia: [],
                    tot: 0
                },
                modalStatsRemote: true,
                quizHasImage: false
            }
        },
        computed: {
            ...mapGetters(MODULES.PATH, [PATH_GETTERS.SELECTED_PATHS, PATH_GETTERS.PATHS]),
            ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.PROFILE]),
            keyWordsComputed: function () {
                const keyWords = _.cloneDeep(this.configuration.keyWords)
                const keyWordsString = keyWords.toString()
                return keyWordsString.replace(/,/g, " ")
            },
            isCheckboxSubjectEmpty: {
                get() {
                    if (!this.isLoading) {
                        const firstTrueCheckbox = _.find(Object.values(this.configuration.checkboxSubjects), (s) => s === true)
                        return firstTrueCheckbox === undefined
                    } else {
                        return false
                    }
                },
                set() {
                    this.configuration.checkboxSubjects = {}
                }
            },
            summedQuestions: function () {
                if (this.summarySelectAllMateria) {
                    return this.currentStats.totDomande
                } else {
                    let questions = ((this.summary.ancoraUnPiccoloSforzo) ? this.currentStats.ancoraUnPiccoloSforzo : 0)
                    questions += ((this.summary.nonRisposte) ? this.currentStats.nonRisposte : 0)
                    questions += ((this.summary.nonLeSai) ? this.currentStats.nonLeSai : 0)
                    questions += ((this.summary.ripetile) ? this.currentStats.ripetile : 0)
                    questions += ((this.summary.leSai) ? this.currentStats.leSai : 0)
                    return questions
                }
            },
            activeFilters: function () {
                let filtersActive = [false, false, false]
                filtersActive[0] = this.configuration.keyWords.length > 0 && this.configuration.keyWords[0] !== "";
                filtersActive[1] = this.configuration.favorite;
                filtersActive[2] = this.configuration.commento;
                return _.filter(filtersActive, (fA) => fA).length
            }
        },
        watch: {
            modalSelectAllMateria: function(newValue, oldValue) {
                if (newValue) {
                    this.modalSubjects = {}
                }
            },
            modalSubjects: {
                deep: true,
                handler (newValue, oldValue) {
                    if (!_.isEmpty(newValue)) {
                        this.modalSelectAllMateria = false
                    }
                }
            },
            summarySelectAllMateria: function(newValue, oldValue) {
                if (newValue) {
                    this.resetSummaryCheckbox()
                    this.commitPath()
                }
            },
            summary: {
                deep: true,
                handler (newValue, oldValue) {
                    if (_.includes(Object.values(newValue), true)) {
                        this.summarySelectAllMateria = false
                        this.commitPath()
                    }
                    if (Object.values(newValue).every(x => x === false)) {
                        this.summarySelectAllMateria = true
                        this.commitPath()
                    }
                }
            },
            configuration: {
                deep: true,
                handler (newValue, oldValue) {
                    const context = this
                    if (this.filtersReady) {
                        this.commitPath()
                        if (!_.isEqual(this.savedConfiguration, newValue)) {
                            if (newValue.keyWords.length === 0 || newValue.keyWords[0] === "") {
                                if (this.savedConfiguration.typeSearchWordKey !== newValue.typeSearchWordKey) {
                                    this.savedConfiguration = _.cloneDeep(newValue)
                                    return
                                }
                            }
                            this.fieldLoader = true
                            this.savedConfiguration = _.cloneDeep(newValue)
                            const nonce = this.generateNonce()
                            try {
                                clearTimeout(performSearchTimeout)
                            } catch (e) { }
                            performSearchTimeout = setTimeout(function() { context.getStats(newValue, nonce) }.bind(null, newValue, nonce), 1000)
                        }
                    }
                }
            },
            orderQuestions: function () {
                this.commitPath()
            },
        },
        mounted() {
            this.backToTop()
            this.verifyPath()
            this.addEventResetModalStatistics()
        },
        methods: {
            ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.GET_ADDITIONAL_RESOURCES]),
            ...mapActions(MODULES.PATH, [PATH_ACTIONS.GET_PATH, PATH_ACTIONS.UPDATE_SELECTED_PATH_BY_INDICE]),
            ...mapActions(MODULES.PROFILE, [PROFILE_ACTIONS.GET_LEVEL, PROFILE_ACTIONS.UPDATE_LEVEL]),
            addEventResetModalStatistics () {
                $('#delStatisticsModal').on('hidden.bs.modal', (e) => {
                    this.modalSelectAllMateria = true
                    this.modalStatsRemote = true
                    this.modalSubjects = {}
                })
            },
            verifyIfNeedToDisableConfirmDelStatsButton () {
                if (!_.isEmpty(this.modalSubjects)) {
                    const subjects = this.subjects.map(s => this.modalSubjects[s.id_materia]).filter(s => s === true)
                    return (subjects.length < 1)
                }
                return this.modalSelectAllMateria === false

            },
            verifyIfQuizHasImage () {
                if (this.quizHasImage) {
                    $('#modalQuizHasImages').modal('show')
                } else {
                    this.setupExercise(this.exerciseMode.ASCOLTATA)
                }
            },
            setupExercise (exerciseMode) {
                if (!this.fieldLoader && this.summedQuestions > 0) {
                    if (this.profile.livello < 502) {
                        this.$emit('is-components-ready', 'pathDetail', false)
                        const knowledgeLevels = []
                        if (this.summary.ancoraUnPiccoloSforzo) { knowledgeLevels.push(LivelliConoscenzaEnum.ANCORA_UN_PICCOLO_SFORZO) }
                        if (this.summary.nonRisposte) { knowledgeLevels.push(LivelliConoscenzaEnum.NON_RISPOSTE) }
                        if (this.summary.nonLeSai) { knowledgeLevels.push(LivelliConoscenzaEnum.NON_LE_SAI) }
                        if (this.summary.ripetile) { knowledgeLevels.push(LivelliConoscenzaEnum.RIPETILE) }
                        if (this.summary.leSai) { knowledgeLevels.push(LivelliConoscenzaEnum.LE_SAI) }
                        percorsiEsercitazioneManager.setupEsercitazione({
                            sortRisposte: this.orderAnswers,
                            sortDomande: this.orderQuestions,
                            livelli_conoscenza: knowledgeLevels,
                            modalita_esercitazione: exerciseMode,
                        })
                            .then((uuid) => {
                                if (exerciseMode === this.exerciseMode.ASCOLTATA) {
                                    percorsiEsercitazioneManager.getEsercitazioneMeta()
                                        .then((res) => {
                                            if (res.n_quiz === 0 || res.n_quiz === undefined) {
                                                this.showModalError('pathDetail.modale.errore.nonCiSonoQuiz')
                                            } else {
                                                this.$emit('is-components-ready', 'pathDetail', true)
                                                this.$router.push({ name: this.routesNames.EXERCISE, params: { uuid: uuid, type: 'materia', mode: 'path' } })
                                            }
                                        })
                                        .catch(() => {
                                            this.showModalError('pathDetail.modale.errore.generale')
                                        })
                                } else {
                                    this.$emit('is-components-ready', 'pathDetail', true)
                                    this.$router.push({ name: this.routesNames.EXERCISE, params: { uuid: uuid, type: 'materia', mode: 'path' } })
                                }
                            })
                            .catch(() => {
                                this.showModalError('pathDetail.modale.errore.generale')
                            })
                    } else {
                        $('#modalLimitQuiz').modal({backdrop: 'static', keyboard: false})
                    }
                }
            },
            verifyResource (resource) {
                return resource !== '' && resource !== null && resource !== undefined;
            },
            generateNonce () {
                const nonce = +new Date()
                lastSearchNonce = nonce
                return nonce
            },
            onAllCheckboxClicked: function (e) {
                if (this.isCheckboxSubjectEmpty) {
                    e.preventDefault()
                    e.stopPropagation()
                }
                this.configuration.checkboxSubjects = {}
            },
            onSelectAllLevelsClick(e) {
                if (this.summarySelectAllMateria) {
                    e.preventDefault()
                    e.stopPropagation()
                }
            },
            /** Controlla se i filtri attualmente selezionati sono custom o meno
             */
            isFilterDefault() {
                return (this.areKeywordsEmpty(this.configuration.keyWords)
                    && this.configuration.typeSearchWordKey === SearchModeEnum.ANY
                    && this.configuration.favorite === false
                    && this.orderQuestions === DEFAULT_ORDER_QUESTIONS
                    && this.configuration.commento === false
                )
            },
            areKeywordsEmpty(kwArray = []) {
                return kwArray.every(x => x == null || x === "")
            },
            verifyIfConfigurationFiltersIsEdit () {
                if (this.selectedPath !== null
                    && this.selectedPath.filters !== undefined && this.selectedPath.filters.configuration !== undefined
                    || $('#collapseExample').hasClass('show')) {
                    return 'show'
                }
            },
            getPathStatus () {
                percorsiStatsManager.getCompletamento(this.selectedPath.id_argomento)
                    .then((res) => this.stateOfCompletion = res)
            },
            getSubjects () {
                const subPathsArray = _.orderBy(Object.values(this.selectedPath.sotto_percorsi), ['nome'], ['asc'])
                _.forEach(subPathsArray, (sottoPercorso) => {
                    this.buildSubPath(sottoPercorso)
                })
                this.totDomande = this.metaData.n_domande
                this.isLoading = false
                this.setupUserConfiguration()
                this.$nextTick(() => {
                  setTimeout(() => {
                    $('#modalDownloadDb').modal('hide')
                    this.init()
                  }, 500)
                })
            },
            buildSubPath (sottoPercorso, indentation, target = this.subjects, prefix) {
                // Preleviamo l'id della subpath selezionata dalla configurazione dell'utente
                const selectedSubPath = _.get(this.selectedPath, "filters.configuration.pathSelected")

                if (indentation === undefined || indentation === null) {
                    indentation = 0
                }
                let ids = (prefix !== undefined) ? prefix + ';' + sottoPercorso.id_sotto_percorso : sottoPercorso.id_sotto_percorso
                let objSubPath = {
                    name: sottoPercorso.nome,
                    indentazione: indentation,
                    checkbox: false,
                    id: ids,
                    open: (selectedSubPath !== undefined) ? includesSottoPercorso(ids, selectedSubPath) && ids !== selectedSubPath : false,
                    sotto_percorsi: [],
                }

                if (!_.isEmpty(sottoPercorso.sotto_percorsi)) {
                    indentation++
                    const sortedSubPaths = _.orderBy(Object.values(sottoPercorso.sotto_percorsi), ['nome'], ['asc']);
                    _.forEach(sortedSubPaths, (sub) => {
                        this.buildSubPath(sub, indentation, objSubPath.sotto_percorsi, ids)
                    })
                }
                target.push(objSubPath)
            },
            buildSubPathOfSubPath (subPath, array) {
                _.forEach(subPath, (subP) => {
                    array.push(subP.id_sotto_percorso)
                    if (!_.isEmpty(subP.sotto_percorsi)) {
                        this.buildSubPathOfSubPath(subP.sotto_percorsi, array)
                    }
                })
            },
            /**
             * A partire da idPath leggiamo da selectedPaths la materia attualmente selezionata e la mettiamo
             * nello stato del componente.
             */
            updateSelectedPathFromVuex () {
                this.selectedPath = _.chain(this.selectedPaths)
                        .find(x => x.id_argomento === this.idPath)
                        .cloneDeep()
                        .value()
            },
            commitPath () {
                // Update the filters of the path before commit on vuex
                this.selectedPath.filters.configuration = this.configuration
                this.selectedPath.filters.summary = this.summary
                this.selectedPath.filters.metaConfig = this.metaConfig
                this.selectedPath.filters.orders = { orderQuestions: this.orderQuestions }

                this.updateSelectedPathByIndice(this.selectedPath);
            },
            verifyPath () {
                this.$emit('is-components-ready', 'pathDetail', false)

                // Rimuoviamo eventuali query param dalla url
                let urlPath = `${process.env.BASE_URL}/materia`.replace("//", "/");
                window.history.pushState({urlPath},"",urlPath)
                const context = this
                if (this.idPath !== undefined) {
                    this.updateSelectedPathFromVuex()
                    this.getSelectedPath(this.selectedPath.id_argomento)
                } else {
                    this.$emit('is-components-ready', 'pathDetail', true)
                    this.$router.push({ name: this.routesNames.HOME })
                }
            },
            getSelectedPath (idPath) {
                const context = this
                this.validatePath(idPath)
                    .then(() => {
                        context.downloadPathQuestions()
                    })
                    .catch(() => {
                        // NOTA: originariamente qui veniva eliminato il path corrispondente a idPath da this.selectedPaths,
                        // tuttavia questa pagina può essere visualizzata solo se la materia è disponibile (vedi PathDetailWrapper)
                        // anziché eliminare la materia da selectedPaths mostriamo semplicemente un messaggio d'errore, se
                        // il path è stato veramente eliminato la prossima volta che verrà aggiornato percorsi_utente
                        // verrà anche eliminato dalla lista
                        this.showModalErrorBlocker('pathDetail.modale.errore.idArgomento')
                    })
            },
            downloadPathQuestions () {
                const context = this
                this.checkIfDBIsAlreadyDownloaded()
                    .then(() => {
                        context.syncStats()
                            .finally(() => {
                                context.getLevel()
                                .finally(() => {
                                    context.countRequests = 0
                                    context.getPathStatus()
                                    context.getSubjects()
                                })
                            })
                    })
                    .catch(() => {
                        context.countRequests++
                        if (context.countRequests > 1) {
                            this.showModalErrorBlocker('pathDetail.modale.errore.aggiungiMateria')
                        } else {
                            context.getPathSelected(context.selectedPath.id_argomento)
                        }
                    })
            },
            checkIfDBIsAlreadyDownloaded () {
                const context = this
                return percorsiWorker.getMeta(this.selectedPath.id_argomento)
                    .then((res) => {
                        context.metaData = res
                        context.isUpdate = moment(context.selectedPath.data_ultimo_aggiornamento).isAfter(context.metaData.data_ultimo_aggiornamento);
                    })
            },
            getPathSelected (idPath) {
                const context = this
                this.getPath({
                    idPercorso: idPath
                })
                    .then(() => {
                        // Aggiorniamo this.selectedPath perché potrebbero essere stati aggiornati i suoi dati
                        context.updateSelectedPathFromVuex()
                        context.addPath()
                    })
                    .catch(() => {
                        this.showModalErrorBlocker('pathDetail.modale.errore.idArgomento')
                    })
            },
            async fetchDataBase(url_domande, context) {
                // Step 1: start the fetch and obtain a reader
                let response = await fetch(url_domande);
                const reader = response.body.getReader();

                // Step 2: get total length
                const contentLength = +response.headers.get('Content-Length');

                // Step 3: read the data
                let receivedLength = 0; // received that many bytes at the moment
                let chunks = []; // array of received binary chunks (comprises the body)
                while(true) {
                    const {done, value} = await reader.read();

                    if (done) {
                        context.downloadProgressValue = 100
                        break;
                    }

                    chunks.push(value);
                    receivedLength += value.length;

                    context.downloadProgressValue = Math.floor(receivedLength * 100 / contentLength);
                }

                // Step 4: concatenate chunks into single Uint8Array
                let chunksAll = new Uint8Array(receivedLength); // (4.1)
                let position = 0;
                for(let chunk of chunks) {
                    chunksAll.set(chunk, position); // (4.2)
                    position += chunk.length;
                }

                return chunksAll;
            },
            addPath () {
                const context = this
                $('#modalDownloadDb').modal({backdrop: 'static', keyboard: false})
                this.$emit('is-components-ready', 'pathDetail', true)
                this.fetchDataBase(this.selectedPath.url_domande, context)
                    .then((ubuffer) => {
                        console.log( this.$route.name  === routesEnums.PATH_DETAIL_WRAPPER )
                        window.fbq('trackCustom', this.$route.name  === routesEnums.PATH_DETAIL_WRAPPER ? 'materia_downloaded' :  'concorso_downloaded')
                        firebase.analytics().logEvent(this.$route.name  === routesEnums.PATH_DETAIL_WRAPPER ? 'materia_downloaded' :  'concorso_downloaded')
                        percorsiWorker.addConcorso({
                            id_concorso: this.selectedPath.id_argomento,
                            data_ultimo_aggiornamento: this.selectedPath.data_ultimo_aggiornamento
                        }, ubuffer)
                            .then(() => {
                                context.downloadPathQuestions()
                            })
                            .catch(() => {
                                setTimeout(() => {
                                    $('#modalDownloadDb').modal('hide')
                                    context.showModalErrorBlocker('pathDetail.modale.errore.aggiungiMateria')
                                }, 500)
                            })
                    })
                    .catch(() => {
                        setTimeout(() => {
                            $('#modalDownloadDb').modal('hide')
                            context.showModalError('pathDetail.modale.errore.downloadDomande')
                        }, 500)
                    })
            },
            setupUserConfiguration () {
                if (this.selectedPath.filters === undefined) {
                    this.selectedPath.filters = {
                        configuration: this.configuration,
                        orders: {
                            orderQuestions: this.orderQuestions,
                        },
                        summary: this.summary,
                        metaConfig: this.metaConfig,
                    }
                    this.summary.nonRisposte = true
                    this.configuration.pathSelected = "all"
                } else {
                    this.orderQuestions = _.get(this.selectedPath, "filters.orders.orderQuestions", DEFAULT_ORDER_QUESTIONS)
                    this.configuration = this.selectedPath.filters.configuration
                    this.summary = this.selectedPath.filters.summary

                    // Controlliamo che isRangeStopCustom sia effettivamente definito, lo mettiamo di default a false altrimenti
                    this.metaConfig.isRangeStopCustom = _.get(this.selectedPath.filters, "metaConfig.isRangeStopCustom", false)
                }
                this.savedConfiguration = _.cloneDeep(this.configuration)
                this.$nextTick(() => {
                    this.filtersReady = true
                    this.isInitialFilterDefault = this.isFilterDefault()
                    this.messageFilter = ((this.isInitialFilterDefault) ? this.$t('pathDetail.apriImpostazioni') : this.$t('pathDetail.chiudiImpostazioni'))
                    this.$nextTick(() => {
                        this.fieldLoader = true
                        this.getStats(this.configuration, this.generateNonce())
                    })
                })
            },
            validatePath (idPath) {
                const context = this
                return new Promise((resolve, reject) => {
                    const path = _.find(context.paths, function (c) { return c.id_argomento === idPath })
                    if (path !== null && path !== undefined) {
                        resolve()
                    } else {
                        reject()
                    }
                })
            },
            syncStats () {
                return percorsiStatsManager.syncStats(this.selectedPath.id_argomento)
            },
            init () {
                const context = this
                $( document ).ready(function() {
                    $('.selectize').selectize()

                    const selectizeTags = $('#selectize-tags').selectize({
                        delimiter: ' ',
                        persist: false,
                        create: function(input) {
                            return {
                                value: input,
                                text: input
                            }
                        },
                        render: {
                            option_create: function(data, escape) {
                                return '<div class="create">' + context.$t('pathDetail.selectize.aggiungi') + '<strong> ' + escape(data.input) + '</strong>&hellip;</div>';
                            }
                        }
                    })

                    selectizeTags.off('change').on('change', (event) => {
                        context.configuration.keyWords = event.target.value.split(' ')
                    })

                    $("#button-ascolto").mouseenter(function() {
                        $("#button-ascolto").find('span').text("Avvia Modalità Ascolto").fadeIn(400);
                    });

                    $("#avvia-test, #button-ascolto, #button-lettura").mouseleave(function() {
                        $("#avvia-test, #button-ascolto, #button-lettura").find('span').text("").fadeOut(300);
                    });

                    if (context.isUpdate) {
                        $('#modalUpdateDb').modal('show')
                        $('#button-update-db').click(() => {
                            context.getPathSelected(context.selectedPath.id_argomento)
                        })
                    }

                    context.$nextTick(() => {
                        context.$emit('is-components-ready', 'pathDetail', true)
                    })
                })
            },
            getStats(filters, nonce) {
                const context = this
                const query = {}
                if (filters !== undefined) {
                    query.id_materie = (filters.pathSelected !== "all") ? [filters.pathSelected] : []
                    query.search = this.areKeywordsEmpty(filters.keyWords) ? [] : filters.keyWords
                    query.preferiti = filters.favorite
                    query.search_mode = filters.typeSearchWordKey
                    query.commento = filters.commento
                }
                percorsiStatsManager.getStatsByFilter(context.selectedPath.id_argomento, query)
                    .then((stats) => {
                        if (nonce === lastSearchNonce) {
                            context.currentStats = {
                                nonRisposte: stats[LivelliConoscenzaEnum.NON_RISPOSTE],
                                nonLeSai: stats[LivelliConoscenzaEnum.NON_LE_SAI],
                                ripetile: stats[LivelliConoscenzaEnum.RIPETILE],
                                ancoraUnPiccoloSforzo: stats[LivelliConoscenzaEnum.ANCORA_UN_PICCOLO_SFORZO],
                                leSai: stats[LivelliConoscenzaEnum.LE_SAI],
                                totDomande: stats.tot
                            }
                            context.quizHasImage = stats.has_images.tot
                            context.fieldLoader = false
                        }
                    })
                    .catch(() => {
                        if (nonce === lastSearchNonce) {
                            context.fieldLoader = false
                            this.showModalErrorBlocker('pathDetail.modale.errore.statistiche')
                        }
                    })
            },
            resetSummaryCheckbox () {
                this.summary.ancoraUnPiccoloSforzo = false
                this.summary.nonRisposte = false
                this.summary.nonLeSai = false
                this.summary.ripetile = false
                this.summary.leSai = false
            },
            emptyStats () {
                if (!this.verifyIfNeedToDisableConfirmDelStatsButton()) {
                    const context = this
                    this.$emit('is-components-ready', 'pathDetail', false)
                    let subjects = undefined
                    if (!_.isEmpty(this.modalSubjects)) {
                        subjects = { id_materie: Object.keys(this.modalSubjects) }
                    }
                    percorsiStatsManager.resetStatsRemote(this.selectedPath.id_argomento, subjects, {remote: this.modalStatsRemote})
                        .then(() => {
                            context.$emit('is-components-ready', 'pathDetail', true)
                            context.fieldLoader = true
                            context.getStats(context.configuration, context.generateNonce())
                            context.checkIfDBIsAlreadyDownloaded()
                            Swal.fire({
                                title: context.$t('pathDetail.sweetAlert.azzeraStatistiche.title'),
                                text: context.$t('pathDetail.sweetAlert.azzeraStatistiche.text'),
                                icon: 'success',
                                confirmButtonText: this.$t('generale.ok')
                            })
                        })
                        .catch(() => {
                            this.showModalError('pathDetail.modale.errore.azzeraStatistiche')
                        })
                }
            },
            getKnowledgeLevel () {
                let query = undefined
                const isSummarySelected = _.filter(Object.keys(this.summary), (s) => this.summary[s] === true)
                if (isSummarySelected !== undefined && isSummarySelected.length > 0) {
                    query = []
                    isSummarySelected.forEach((summarySelected) => {
                        if (summarySelected === "ancoraUnPiccoloSforzo") {
                            query.push(LivelliConoscenzaEnum.ANCORA_UN_PICCOLO_SFORZO)
                        }
                        if (summarySelected === "leSai") {
                            query.push(LivelliConoscenzaEnum.LE_SAI)
                        }
                        if (summarySelected === "nonLeSai") {
                            query.push(LivelliConoscenzaEnum.NON_LE_SAI)
                        }
                        if (summarySelected === "nonRisposte") {
                            query.push(LivelliConoscenzaEnum.NON_RISPOSTE)
                        }
                        if (summarySelected === "ripetile") {
                            query.push(LivelliConoscenzaEnum.RIPETILE)
                        }
                    })
                }
                return query
            },
            forceSync(type) {
                this.$emit('is-components-ready', 'pathDetail', false)
                if (type === 'fromWeb') {
                    percorsiStatsManager.forceSyncFromRemote(this.selectedPath.id_argomento)
                        .then(() => {
                            this.fieldLoader = true
                            this.showSweetAlertSync(this.$t('pathDetail.sweetAlert.forzaSincronizzazione.dalWeb'))
                            this.checkIfDBIsAlreadyDownloaded()
                            this.getStats(this.configuration, this.generateNonce())
                        })
                        .catch(() => {
                            this.showModalSyncError('pathDetail.modale.errore.forceSync')
                        })
                } else {
                    percorsiStatsManager.forceSyncToRemote(this.selectedPath.id_argomento)
                        .then(() => {
                            this.showSweetAlertSync(this.$t('pathDetail.sweetAlert.forzaSincronizzazione.versoIlWeb'))
                        })
                        .catch(() => {
                            this.showModalSyncError('pathDetail.modale.errore.forceSync')
                        })
                }
            },
            computeCollapseFilterMessage () {
                const context = this
                this.$nextTick(() => {
                    if ($('#collapseExample').hasClass('show')) {
                        context.messageFilter = context.$t('pathDetail.apriImpostazioni')
                    } else {
                        context.messageFilter = context.$t('pathDetail.chiudiImpostazioni')
                    }
                })
            },
            backToTop () {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            },
            showModalError(message) {
                const context = this
                this.$emit('is-components-ready', 'pathDetail', true)
                $('#modalErrorPathDetail').modal('show')
                $('#message-body-error-path-detail').html(context.$t(message))
            },
            showModalErrorBlocker(message) {
                const context = this
                this.$emit('is-components-ready', 'pathDetail', true)
                $('#modalErrorPath').modal({backdrop: 'static', keyboard: false})
                $('#message-body-path').html(context.$t(message))
            },
            formatData(data) {
                return moment(data).format('DD-MM-YYYY HH:mm')
            },
            showModalSyncError(message) {
                const context = this
                this.$emit('is-components-ready', 'pathDetail', true)
                $('#modalErrorForceSync').modal('show')
                $('#message-body-error-force-sync').html(context.$t(message))
            },
            showSweetAlertSync (text) {
                this.$emit('is-components-ready', 'pathDetail', true)
                Swal.fire({
                    title: this.$t('pathDetail.sweetAlert.forzaSincronizzazione.titolo'),
                    text: text,
                    icon: 'success',
                    confirmButtonText: this.$t('generale.ok')
                })
            },
            getQuestionsForPath (idPath) {
                if (idPath !== undefined) {
                    const nDomande = Object.entries(this.metaData.n_domande_by_materia)
                    return _.sum(nDomande
                        .filter(x => includesSottoPercorso(idPath, x[0]))
                        .map(x => x[1])
                    )
                }
            },
            isOpen (status) {
                return !!status;
            },
            changeIconAccordion (idElement) {
                const $element = $(idElement)
                if ($element.hasClass('mdi-file-document-box-plus-outline')) {
                    $element.addClass('mdi-file-document-box-minus-outline').removeClass('mdi-file-document-box-plus-outline');
                } else {
                    $element.addClass('mdi-file-document-box-plus-outline').removeClass('mdi-file-document-box-minus-outline');
                }
            },
            getCompletitionByMateria(idPath) {
                if (idPath !== undefined) {
                    const path = sanitizeSottoPercorsoId(idPath)
                    return this.parsePct(this.stateOfCompletion.by_materia[path])
                }
                else {
                    return 0;
                }
            },
            parsePct (v) {
                return getNumberBetween(v, 0, 100)
            }
        }
    }
</script>

<style scoped>
    @import "~selectize/dist/css/selectize.css";
    @import "~selectize/dist/css/selectize.default.css";
    @import '~vue-slider-component/theme/default.css';
</style>

<style scoped lang="scss">
    html.theme-dark {
        .border-center-content {
            border-top: 1px solid #2f3e46;
            align-items: center;
        }

        .bg-subpath {
            background-color: #2c3a42;
        }
    }

    html.theme-light {
        .border-center-content {
            border-top: 1px solid #eff2f7;
            align-items: center;
        }

        .bg-subpath {
            background-color: #fbfbfd;
        }
    }

    html.theme-light, html.theme-dark {
        .ptbr-element {
            padding-top: .75rem !important;
            padding-bottom: .75rem !important;
            padding-right: .75rem !important;
        }

        .pl-custom {
            padding-left: 35px !important;
        }

        .custom-max-width {
            max-width: 7.2% !important;
        }

        .custom-radio-size {
            width: 1.1rem;
            height: 1.1rem;
        }

        .custom-font {
            font-weight: 600;
            font-size: 14px !important;
        }

        .padding-element {
            padding: .75rem !important;
        }

        .pt-6 {
            padding-top: 2.5rem;
        }

        .bg-disabled {
            background-color: #f8f9fa;
        }

        .switch {
            height: 24px;
            width: 50px;
        }

        .slider:before {
            height: 16px;
            width: 16px;
        }

        .nav-tabs-custom .nav-item .nav-link:hover {
            background: #3051d3;
            color: #fff !important;
        }

        .nav-tabs-custom .nav-item .nav-link {
            color: #74797d;
            border-top-left-radius: .25rem;
            border-top-right-radius: 3.95rem;
        }

        .nav-tabs .nav-link.active {
            background: #3051d3 !important;
            color: #fff !important
        }

        .nav-tabs .nav-link.active h6 {
            color: #fff !important
        }

        #label-filters-favorite-checkbox:before {
            border-radius: 0 !important;
        }

        .field-loader {
            border: 5px solid #f3f3f3;
            border-radius: 50%;
            border-top: 5px solid #3051d3;
            width: 28px;
            height: 28px;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
        }

        .field-loader-sum {
            border: 5px solid #f3f3f3;
            border-radius: 50%;
            border-top: 5px solid #3051d3;
            width: 35px;
            height: 35px;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
        }

        /* Safari */
        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        @media (max-width: 1279px) {
            .custom-pt-sm-3 {
                padding-top: 1rem !important;
            }
        }
    }
</style>
